import React from "react";
import { graphql } from "gatsby"
import Layout from "../components/layout"

const IndexPage = ({ data }) => (
    <Layout>
        <h5>Welcome</h5>
        <p>
          I'm a freelance Senior Software Developer and Architect with 20+ years work experience, based in Den Bosch, the Netherlands.
          Since 2002 I've worked professionally for several small and large (international) companies in various branches. 
          In these years I've developed into a Senior (Full Stack) Java Developer and gained experience with many different architectures, methodologies, frameworks, tooling and databases.
          I'm mainly focused on <b>backend development</b> and experienced in:
          <br/>
          <br/>
          <i><b>Java (8/11/17/21) Kotlin, Java EE, Spring Framework / Spring Boot / Vert.x, Maven / Gradle, CI/CD, SQL / NoSQL, 
            Hibernate, REST APIs, Webservices, Microservices, AWS, Docker, Kubernetes</b></i>
          <br/>
          <br/>
          but I'm also quite experienced in <b>frontend development</b>, especially with:
          <br/>
          <br/>
          <i><b>TypeScript, Angular (8/13/16), React, Gatsby, HTML 5</b></i>
          <br/>
          <br/>
          In recent years I gained the following certifications:
          <br/>
          <br/>
          <i><b>Oracle Certified Java 8 Professional</b></i>, <i><b>Spring Certified Professional</b></i>, <i><b>Kotlin for Java Developers</b></i>, <i><b>AWS Certified Developer</b></i> and <i><b>AWS Certified Solutions Architect</b></i>.
          <br/>
          <br/>
          In addition to the technical skills I'm equipped with well-developed analytical, social and communicative skills.
          I have a team oriented <b>agile</b> mindset and I'm experienced with <b>Scrum</b> (PSM1 certified) and <b>DevOps</b>.
          I like to oversee the 'big picture' and think about <b>software architecture</b> and <b>software integration</b> design and solutions.
          I embrace <b>Clean Architecture</b>, <b>DDD</b> (Domain Driven Design), <b>TDD</b> (Test Driven Development) and <b>Clean Code</b> (SOLID) principles in order
          to ensure software quality and good software development craftsmanship.
          <br/>
          <br/>
          I'm energized by adding business value, learning new things and keeping a close eye on new technologies.
          <br/>
          Entrust your technological challenge(s) to me!
        </p>
    </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default IndexPage;
